import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Layout from "../components/layout";
import Back from "../components/backbutton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    maxWidth: 1500,
    minHeight: 600,
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     cols: 2,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

 import image from '../images/galerie/1.jpg';
 import image2 from '../images/galerie/2.jpg';

 const tileData = [
   {
    img: image,
    title: 'Image',
    author: 'author',
    cols: 1,
   }, 
   {
    img: image2,
    title: 'Image',
    author: 'author',
    cols: 2,
   }, 
 
];
export default function ImageGridList() {
  const classes = useStyles();

  return (
      <Layout>
<Back></Back>
     
    <div className={classes.root}>
      <GridList cellHeight={160} className={classes.gridList} cols={3}>
        {tileData.map((tile) => (
          <GridListTile key={tile.img} cols={tile.cols || 1}>
            <img src={tile.img} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
    </Layout>
  );
}
